import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders({
    'Content-Type': "application/json"
  });

  contactUsr(data: any) {
    return this.http.post(environment.host + "contact", data, { headers: this.headers });
  }
}
