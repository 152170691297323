import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PgMetaService {

  constructor(private title: Title, private meta: Meta, private router: Router) { }

  setPgTitle(pgTitle: string) {
    this.title.setTitle(pgTitle+' | Magica Labs');
    this.meta.updateTag({ name: 'twitter:title', content: pgTitle+" | Magica Labs"}),
    this.meta.updateTag({ property: 'og:title', content: pgTitle+" | Magica Labs" });

    this.setPgURL();
  }

  setPgDes(pgDes: string) {
    this.meta.updateTag({ name: 'description', content: pgDes+' - Magica Labs'});
    this.meta.updateTag({ property: 'og:description', content: pgDes+' - Magica Labs'});
    this.meta.updateTag({ name: 'twitter:description', content: pgDes+" - Magica Labs"});
  }

  setPgKey(pgKey: string) {
    this.meta.updateTag({ name: 'keywords', content: pgKey+' - Magica Labs'});
  }

  setPgImg(pgImg: string = "https://magicalabs.com/assets/images/Magicalab-logo.png") {
    this.meta.updateTag({ property: 'og:image', content: pgImg});
    this.meta.updateTag({ name: 'twitter:image', content: pgImg});
  }

  setPgURL() {
    this.meta.updateTag({ property: 'og:url', content: "https://magicalabs.com"+this.router.url});
    this.meta.updateTag({ name: 'twitter:url', content: "https://magicalabs.com"+this.router.url});
  }
}
